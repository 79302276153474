<template>
	<notifications class="messages" pause-on-hover :style="cssOverride">
		<template #body="{ item, close }">
			<sk-toast
				v-if="item.data.isToast"
				class="message"
				data-test-id="notifications-band"
				:level="item.type"
				rounded="xs"
				:text="item.text"
				:theme="contrastTheme"
				:title="item.title"
				:width="width"
				@update:model-value="chain(item.data.cancelAction, close)"
			/>
			<sk-dialog
				v-model="item.data.isDialog"
				:alert="asAlert(item)"
				data-test-id="feedback-dialog"
				:icon="item.data.icon"
				:level="item.type"
				:persistent="item.data.persistent"
				:subtitle="item.text"
				:title="item.title"
				@click:outside="chain(item.data.cancelAction)"
				@update:model-value="chain(item.data.cancelAction, close)"
			>
				<template #footer>
					<sk-secondary-btn data-test-id="secondary-action" @click="chain(item.data.cancelAction, close)">
						{{ item.data.closeBtnLabel }}
					</sk-secondary-btn>
					<sk-primary-btn
						v-if="item.data.actionBtn"
						class="ml-md"
						data-test-id="primary-action"
						@click="chain(item.data.actionBtn.action, close)"
					>
						{{ item.data.actionBtn.label }}
					</sk-primary-btn>
				</template>
			</sk-dialog>
		</template>
	</notifications>
</template>

<style lang="scss" scoped>
.messages {
	--mys-message-width: 540px;
	width: var(--mys-message-width) !important;

	top: unset !important;
	bottom: var(--sk-app-bar-height);
	right: calc(50% - (var(--mys-message-width) / 2)) !important;

	:has(> .vue-notification-wrapper) {
		display: flex;
		flex-direction: column;
		gap: var(--sk-spacing-sm);
	}
}
</style>

<script lang="ts" setup>
import { computed } from 'vue';

import { Alert, SkDialog, SkPrimaryBtn, SkSecondaryBtn, SkToast } from '@silae/components';
import { useTheme } from '@silae/composables';
import { Optional } from '@silae/helpers';
import { Notification } from '~/services';

const props = withDefaults(
	defineProps<{
		width?: string;
	}>(),
	{
		width: '540px'
	}
);

defineEmits<{
	(e: 'confirmed'): void;
}>();

const cssOverride = computed(() => `--mys-message-width:${props.width}`);

const { contrastTheme } = useTheme();

function asAlert(item: Notification): Optional<Alert> {
	if (item.data?.alertText == null && item.data?.alertTitle == null) return;

	return {
		level: item.type,
		text: item.data?.alertText,
		title: item.data?.alertTitle
	};
}

const chain = (...actions: Array<Optional<() => void>>) => {
	if (actions) {
		actions.filter(action => action != undefined).forEach(action => action());
	}
};
</script>
