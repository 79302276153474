import { DateTime, DateTimeFormatOptions, Duration } from 'luxon';

import { Optional } from '@silae/helpers';
import { ISODateString, ISODateTimeString } from '~/api';

// Custom date range type, instead of using v-calendar's SimpleDateRange (not exported properly)
export type DateRange = { start: Date; end: Date };

export const ISO_FORMAT = 'y-MM-dd'; // ex: 2024-04-01
export const ISO_FORMAT_TIME = "yyyy-MM-dd'T'HH:mm:ss"; // ex: 2024-04-01T09:00:00
export const PAY_PERIOD = 'LLLL yyyy'; // ex: avril 2024

export function ISODateTimeAsLocalDate(date: ISODateTimeString, format: DateTimeFormatOptions = DateTime.DATE_FULL): Optional<string> {
	return ISODateAsLocalDate(date, format);
}

export function ISODateAsLocalDate(ISO: Optional<ISODateString>, format: DateTimeFormatOptions = DateTime.DATE_FULL): Optional<string> {
	if (ISO == undefined) return undefined;
	return DateTime.fromISO(ISO).toLocaleString(format);
}

export function ISODateAsLocalDateTime(ISO: Optional<ISODateString>): Optional<string> {
	if (ISO == undefined) return undefined;
	const datetime = DateTime.fromISO(ISO);
	return `${datetime.toLocaleString(DateTime.DATE_SHORT)} ${datetime.toLocaleString(DateTime.TIME_SIMPLE)}`;
}

export function ISODateAsDate(ISO: Optional<ISODateString>): Optional<Date> {
	if (ISO == undefined) return undefined;

	return DateTime.fromISO(ISO).toJSDate();
}

export function dateAsLocalDate(date: Date, format: DateTimeFormatOptions = DateTime.DATE_FULL): Optional<string> {
	if (date == undefined) return undefined;
	return DateTime.fromJSDate(date).toLocaleString(format);
}

export function asFormattedDate(date: Date | string, format: string): Optional<string> {
	if (date == undefined) return undefined;
	return DateTime.fromISO(date.toString()).toFormat(format);
}

export function dateAsISODate(date: Optional<Date>): Optional<ISODateString> {
	if (date == undefined) return undefined;
	// turn null into undefined
	return DateTime.fromJSDate(date).toISODate() ?? undefined;
}

export function dateAsISODateAtStartOfDay(date: Optional<Date>): Optional<ISODateString | null> {
	if (date == undefined) return undefined;
	return DateTime.fromJSDate(date).startOf('day').toISODate();
}

export function splitHoursAndMinutes(hours: number): { hours?: number; minutes?: string } {
	if (hours == null) return { hours: undefined, minutes: undefined };
	const totalMinutes = Math.round(hours * 60);
	const hoursPart = Math.floor(totalMinutes / 60);
	const minutesPart = String(totalMinutes % 60).padStart(2, '0');

	return { hours: hoursPart, minutes: minutesPart };
}

export function getDateWithoutTimezone(date: Date): Date {
	const localTimezoneOffset = date.getTimezoneOffset();
	return new Date(date.getTime() - localTimezoneOffset * 60 * 1000);
}

export function getCurrentMonth(): string {
	const currentWeekNumber = DateTime.now().weekNumber.valueOf();
	return DateTime.local()
		.startOf('year')
		.plus({ weeks: currentWeekNumber - 1 })
		.toFormat('LLLL');
}

export function isSameDay(d1: Date, d2: Date): boolean {
	return d1?.getFullYear() === d2?.getFullYear() && d1?.getMonth() === d2?.getMonth() && d1?.getDate() === d2?.getDate();
}

export function getMonthName(monthIndex: Optional<number>, locale: string, format: 'long' | 'short' = 'long') {
	if (monthIndex == undefined) return '-';
	const formatter = new Intl.DateTimeFormat(locale, { month: format });
	// use random year as only month is of interest
	return formatter.format(new Date(2131, monthIndex, 1));
}

export function getMonthList(locale: string, format: 'long' | 'short' = 'long'): string[] {
	const monthList = [...Array(12).keys()]; // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
	return monthList.map(it => getMonthName(it, locale, format));
}

export function getHoursFromISODateTime(ISO: Optional<ISODateTimeString>, defaultReturn: string): string {
	if (!ISO) return defaultReturn;

	const dateTime = DateTime.fromISO(ISO);

	if (!dateTime.isValid) return defaultReturn;

	const { hour, minute } = dateTime;

	return Duration.fromObject({ hour, minute }).toFormat('hh:mm');
}

export function isValidBirthDate(date: Date): boolean {
	const birthDateLowerLimit = DateTime.now().minus({ years: 130 }).toJSDate();
	const birthDateUpperLimit = DateTime.now().minus({ years: 10 }).toJSDate();
	return date && date > birthDateLowerLimit && date < birthDateUpperLimit;
}

export function isAfterNow(ISO: ISODateTimeString): boolean {
	return DateTime.fromISO(ISO) > DateTime.now();
}

export function isBeforeNow(ISO: ISODateTimeString): boolean {
	return DateTime.fromISO(ISO) < DateTime.now();
}

export function generateDateRange(start: Optional<DateTime>, end: Optional<DateTime>): Array<DateTime> {
	if (!start || !end) return [];

	const days = end.diff(start, 'days').days;
	return Array.from({ length: days + 1 }, (_, i) => start.plus({ days: i }));
}
